











































































































































































import { Component, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ReservedExamListFiltersComponent from '@/components/filters/ReservedExamListFilter.component.vue';
import DialogComponent from '@/components/dialog.component.vue';
import ReserveExamProvider from '@/providers/reserveExam.provider';
import { ReserveExamFiltersResponse } from '../../types/exam.type';
import ReserveExamsListMixins from '../../mixins/reserveExamsList.mixin';
import {
  APPIDS,
  EXAM_CENTER_REQUEST_STATUS,
  RESERVE_EXAM_ROUTE_NAME,
  Rights,
} from '../../enums';
import SelectedCourse from '../SelectedCourse.component.vue';

@Component({
  components: {
    ReservedExamListFiltersComponent,
    DialogComponent,
    SelectedCourse,
  },
})
export default class ReserveExamsList extends mixins(ReserveExamsListMixins) {
  @Provide() Provider = ReserveExamProvider;

  appId: string = APPIDS.RESERVE_EXAM;

  courseId: string = this.$route?.params.courseId;

  userRights: string[] = [];

  filtersData: ReserveExamFiltersResponse = {
    examTypes: [],
    semesters: [],
    academicYears: [],
    requestsStatuses: [],
    centers: [],
  };

  get headers() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.reserveTableExamType'),
        sortable: false,
        value: 'examType',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.academicYear'),
        sortable: false,
        align: 'center',
        value: 'academicYear',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.semester'),
        sortable: false,
        align: 'center',
        value: 'semester',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.requestDate'),
        sortable: true,
        align: 'center',
        value: 'examDate',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.requestStatus'),
        sortable: true,
        align: 'center',
        value: 'status',
        class: this.headerClass,
      },
      {
        sortable: false,
        align: 'center',
        value: 'actions',
        class: this.headerClass,
        width: '150px',
      },
    ];
  }

  async mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
  }

  deleteExamRequestConfirm(examRequestId) {
    this.toDeleteExamRequest = examRequestId;
    this.showDeleteConfirm = true;
  }

  goReserveExamDetails(row) {
    this.$router.push({
      name: RESERVE_EXAM_ROUTE_NAME.RESERVE_EXAM_DETAILS,
      params: {
        courseId: this.courseId,
        reserveExamId: row.examRequestId,
      },
    });
  }

  get canDelete() {
    return this.userRights.includes(Rights.delete);
  }

  get canAdd() {
    return this.userRights.includes(Rights.add);
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  isConfirmedStatus(examRequest) {
    return Boolean(
      examRequest.status.id === EXAM_CENTER_REQUEST_STATUS.ACCEPTED,
    );
  }

  goCoursesList() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: RESERVE_EXAM_ROUTE_NAME.COURSES_LIST, query: courseFilters });
  }

  addReservation() {
    this.$router.push({
      name: RESERVE_EXAM_ROUTE_NAME.ADD_RESERVATION,
    });
  }

  formateDate(date) {
    return new Date(date as string).toLocaleDateString(this.lang);
  }
}
