import { CourseFiltersInputs, CourseListProvider, CoursesListResponse } from '@/types/course.type';
import {
  ExamFilters, ExamRequest, ImportStudentsResponse, ReservationRequest, ReserveExamListResponse, SaveReservationResponse,
} from '@/types/exam.type';
import { Lang } from '@/types/general.type';
import provider from './main.provider';

class ReserveExamProvider implements CourseListProvider {
  async getAppCourseList(appId: string, query: CourseFiltersInputs): Promise<CoursesListResponse> {
    const courseFilters = { ...query, activeStatus: '1' };
    const result: CoursesListResponse = await provider.get(`/api/courses/${appId}/getList`, {
      params: courseFilters,
    });
    return result;
  }

  async getExamsRequests(appId: string, courseId: string, query: object) {
    const result: ReserveExamListResponse = await provider.get(
      `/api/reserveExam/${appId}/getReservedExams/${courseId}`,
      { params: query },
    );
    return result;
  }

  async GetFilters(appId: string, courseId: string, query: object) {
    const result: ExamFilters = await provider.get(
      `/api/reserveExam/${appId}/getReservedExamsFilters/${courseId}`,
      { params: query },
    );
    return result;
  }

  async deleteReservedExamData(
    appId: string,
    courseId: string,
    reservedExamId: string,
  ): Promise<{ message: Lang }> {
    return provider.delete(`/api/reserveExam/${appId}/${courseId}/${reservedExamId}`);
  }

  async importStudentsCount(appId: string, courseId: string, query): Promise<ImportStudentsResponse> {
    return provider.get(`/api/reserveExam/${appId}/getCourseStudentsCount/${courseId}/`, {
      params: query,
    });
  }

  async addReservedExam(appId: string, courseId: string, reqData: ReservationRequest): Promise<SaveReservationResponse> {
    const result: SaveReservationResponse = await provider.post(
      `/api/reserveExam/${appId}/addReservedExam/${courseId}`, reqData,
    );
    return result;
  }

  async getReservationDetails(appId: string, courseId: string, reservationId: string) {
    const result: ExamRequest = await provider.get(
      `/api/reserveExam/${appId}/${courseId}/${reservationId}`,
    );
    return result;
  }
}
export default new ReserveExamProvider();
