import Component from 'vue-class-component';
import { Route, Query } from '@/types/router.type';
import { SortData } from '@/types/questions.type';
import { ReserveExamListResponse, ReserveExamBasic, ReservedExamsInputs } from '@/types/exam.type';
import reserveExamProvider from '@/providers/reserveExam.provider';
import { GeneralResponse, Lang } from '@/types/general.type';
import Toaster from './toaster.mixin';

@Component
export default class ReserveExamsListMixins extends Toaster {
  appId = '';

  courseId = '';

  loading = false;

  loadingFilters = true;

  queries: Query = {};

  userRights: string[] = [];

  ReserveExamsList: ReserveExamBasic[] = [];

  totalItems = 0;

  limit = 10;

  page = 1;

  error = '';

  toDeleteExamRequest = '';

  loadingDelete = false;

  showDeleteConfirm = false;

  filtersInputs: ReservedExamsInputs = {
    academicYear: '',
    center: [],
    status: '',
    examType: '',
    validFilters: false,
    semester: '',
    examFromDate: '',
    examToDate: '',
  };

  headerClass = 'grey darken-4 white--text pa-3 qb-table__header';

  sortKeys: string[] = [];

  sortDesc: boolean[] = [];

  limitOption = [5, 10, 20, 50];

  get headerOptions() {
    return { sortByText: this.$t('labels.sortBy') };
  }

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
      disablePagination: this.loading,
      'show-first-last-page': true,
    };
  }

  created() {
    this.setQueries();
  }

  mounted() {
    this.fetchFiltersData();
  }

  setQueries() {
    const route: Route = this.$route as Route;
    this.queries = route.query;
    this.limit = Number(this.queries.limit) || 10;
    this.page = Number(this.queries.page) || 1;
    if (this.queries.orderBy !== undefined) {
      this.sortKeys[0] = this.queries.orderBy;
    }
    if (this.queries.desc === 'true') {
      this.sortDesc[0] = true;
    }
  }

  fetchFiltersData() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      this.filtersInputs[key] = this.queries[key] ? this.queries[key] : '';
    });
  }

  resetSearch() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (key !== 'validFilters') {
        this.filtersInputs[key] = '';
      }
    });
    this.search();
  }

  search() {
    const temp = this.filtersInputs.center;
    const tempStatus = this.filtersInputs.status;
    const queryData = {
      ...this.filtersInputs,
      center: temp.toString(),
      status: tempStatus.toString(),
    };
    this.filtersInputs.center = temp;
    this.filtersInputs.status = tempStatus;
    delete queryData.validFilters;
    this.queries = ({ ...queryData } as unknown) as Query;
    this.limit = this.limit || 10;
    this.page = 1;
    this.getReserveExamsList();
  }

  onOptionUpdate(options) {
    this.page = options.page;
    this.limit = options.itemsPerPage;
    this.sortKeys = options.sortBy;
    this.sortDesc = options.sortDesc;
    this.getReserveExamsList();
  }

  getItemIndex(item) {
    return this.ReserveExamsList.indexOf(item);
  }

  async getReserveExamsList() {
    try {
      this.loading = true;

      delete this.queries.desc;
      const reqQuery = {
        ...this.queries,
        limit: `${this.limit}`,
        page: `${this.page}`,
        offset: `${(this.page - 1) * this.limit}`,
      };

      const sortData: SortData = { orderBy: this.sortKeys[0] };
      if (this.sortDesc[0]) {
        const [desc] = this.sortDesc;
        sortData.desc = desc.toString();
      }

      const {
        examRequests,
        totalCount,
        rights,
      }: ReserveExamListResponse = await reserveExamProvider.getExamsRequests(
        this.appId,
        this.courseId,
        { ...reqQuery, ...sortData },
      );

      this.loading = false;
      this.ReserveExamsList = examRequests as ReserveExamBasic[];
      this.userRights = rights as string[];

      this.totalItems = totalCount as number;

      this.$store.commit('AppFilters', {
        ...this.$store.state.filters.appFilters,
        [this.appId]: { ...reqQuery, ...sortData },
      });

      this.$router.replace({ query: { ...reqQuery, ...sortData } });
    } catch (err) {
      this.error = err as string;
      this.loading = false;
      this.ReserveExamsList = [];
    }
  }

  async deleteExamRequest() {
    try {
      this.loading = true;
      this.loadingDelete = true;
      const { message }: GeneralResponse = await reserveExamProvider.deleteReservedExamData(
        this.appId,
        this.courseId,
        this.toDeleteExamRequest,
      );
      this.loading = false;
      this.loadingDelete = false;
      this.toDeleteExamRequest = '';
      this.showDeleteConfirm = false;
      if (this.page === 1) {
        this.getReserveExamsList();
      } else {
        this.page = 1;
      }
      this.successToaster(message as Lang, { duration: 5000 });
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
      this.loading = false;
      this.loadingDelete = false;
      this.showDeleteConfirm = false;
    }
  }
}
